import React from 'react'
import map from '../images/map.png'

const NotFound = () => {
  return (
    <div className="flex  flex-col justify-center items-center pt-24 font-raber39">
      <img src={map} alt="map" className="w-3/4 sm:w-1/2 mx-auto xl:max-w-lg" />
      <h1 className=" text-center text-base md:text-lg lg:text-3xl  font-bold text-gray-500 mt-4  p-4"> ئەم پەڕەیە بوونی نیە</h1>
      <button
      type="button"
      className="btn mt-5 btn-primary border-none sm:btn-lg md:btn-xl lg:w-[18rem] lg:h-[4rem]  rounded-full font-raber39 tracking-wide bg-green-400 hover:bg-green-500 "
    >
بگەڕێرەوە    </button>

    </div>
  )
}

export default NotFound