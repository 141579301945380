import React from 'react';

const RegisterButton = () => {
  return (
    <button
      type="button"
      className="btn btn-primary border-none sm:btn-lg md:btn-md rounded-full font-raber39 tracking-wide  bg-orange-400 hover:bg-orange-500 shadow-sm "
    >
      خۆت تۆمار بکه
    </button>
  );
};

export default RegisterButton;
