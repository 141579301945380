import React from 'react';

const Card = ({ itemImage, itemName, date, authImage, authName, authRole }) => {
  return (
    <div className="w-full xl:max-w-[21rem] lg:max-w-md  flex-shrink-0 inline-block  group hover:scale-95 cursor-pointer transition duration-200">
      <div className="relative">
        <img
          src={itemImage}
          alt="blog"
          className="w-full  h-52 md:h-52 lg:h-64 xl:h-72 object-cover object-center rounded-md border border-stone-400"
        />
      </div>

      <div className="flex flex-col justify-between pt-2 font-raber39 px-1 gap-2 md:gap-4 lg:gap-4 xl:gap-5">
        <div className=" text-right text-[0.70rem] md:text-[0.85rem] lg:text-sm xl:text-base font-bold text-stone-700 ">
          {itemName}
        </div>
        <div className="flex justify-between items-center mt-1">
          
            <div className=" font-raber39 flex flex-row-reverse items-center gap-1  text-[0.52rem] bg-stone-50 border border-stone-600 py-1 px-2 rounded text-stone-600">
              {/* <svg
                width="16"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                // eslint-disable-next-line prettier/prettier
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  // eslint-disable-next-line prettier/prettier
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg> */}
              <span className="font-sans text-[0.55rem] lg:text-xs font-bold">{date}</span>
            </div>
          
          <div className="flex items-center gap-1 lg:gap-2 justify-start flex-row-reverse text-right ">
            <img
              className="w-7 h-7 sm:w-8 sm:h-8 lg:w-9 lg:h-9  mask mask-squircle object-cover object-center "
              src={authImage}
              alt=""
            />
            <div className="font-medium dark:text-white mt-1">
              <div className=" text-stone-700 text-xs lg:text-sm">{authName}</div>
              <div className="text-[0.60rem] lg:text-xs text-stone-500 ">{authRole}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
