import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UserAuth } from '../context/AuthContext';
import { googleLogo } from '../images/svg/svg';

const AuthPage = () => {
  const { signInWithGoogle, currentUser } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser != null) {
      navigate('/');
    }
  }, [currentUser , navigate]);

  return (
    <div className="font-raber39 h-screen flex flex-col justify-center  items-center  ">
      <button
        onClick={signInWithGoogle}
        className="btn group border border-stone-500 hover:bg-stone-800  bg-transparent flex flex-row-reverse gap-3"
      >
        {googleLogo}
        <span className="text-stone-600 group-hover:text-stone-100 ">
          به هەژماری گووگڵ خۆت تۆمار بکه
        </span>
      </button>
    </div>
  );
};

export default AuthPage;
