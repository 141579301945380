import React from 'react';

const PlaceCard = ({ name, image, color }) => {
  return (
    <div className="relative group ">
      <div
        className={`relative  w-36 h-36 md:w-48  md:h-48 lg:w-52 lg:h-52 xl:w-60 xl:h-60 mask mask-squircle  bg-gradient-to-r ${color} opacity-80 group-hover:opacity-100 transition`}
      ></div>
      <img
        src={image}
        alt={name}
        className={` absolute scale-105 md:scale-125 -left-1 top-8 group-hover:scale-125 transition group-hover:rotate-12 `}
      />
    </div>
  );
};

export default PlaceCard;
