import { Link } from 'react-router-dom';
import Card from '../components/Card/Card';
import Empty from '../components/Empty/Empty';



function FoundItemsPage({ foundItems }) {
  
if(foundItems.length === 0) return <div className='flex  flex-col justify-center items-center pt-24 '>
<Empty category={'foundItems'}/>
  </div>
  
  return (
    <div className="font-raber39 w-full xl:max-w-[90rem] mx-auto pt-10 px-4 ">
      <div className="text-end text-stone-500 my-8 mr-5">: شتە دۆزروەکان </div>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 grid-flow-dense justify-center gap-10 md:gap-8 ">
        {foundItems.map((item) => (
          <Link key={item.id} state={{ itemData: item }} to={`/items/${item.id}`}>
            <div
              key={item.id}
              className="flex-initial w-64 lg:w-72 xl:w-80 relative flex-shrink-0 mx-auto p-1 lg:my-5"
            >
              <Card
                itemImage={item.image}
                itemName={item.itemName}
                date={item.date}
                authName={item.authName}
                authImage={item.authImage}
                authRole={item.authRole}
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default FoundItemsPage;
