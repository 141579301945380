import React from 'react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ href, children, toggleDropDown }) => {
  return (
    <li>
      <NavLink
        className={({ isActive }) =>
          isActive
            ? 'text-amber-500 transition font-raber39 text-base'
            : 'text-stone-500/75 hover:text-stone-500 hover:scale-105 transition font-raber39 text-base'
        }
        to={href}
        onClick={toggleDropDown}>
        {children}
      </NavLink>
    </li>
  );
};

export default NavItem;
