import { UserAuth } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ children }) => {
  const { currentUser } = UserAuth();

  if (currentUser == null) return <Navigate to="/signin" />;
  return children;
};

export default ProtectedRoutes;
