import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HomePage from './pages/HomePage';
import SingleItemPage from './pages/SingleItemPage';
import LostItemsPage from './pages/LostItemsPage';
import AuthPage from './pages/AuthPage';
import FoundItemsPage from './pages/FoundItemsPage';
import NavbarComponent from './components/Navbar/Navbar';
import DropDown from './components/Navbar/DropDown';
import Footer from './components/Footer/Footer';
import UploadItemPage from './pages/UploadItemPage';
import ProfilePage from './pages/ProfilePage';
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import Scroll from './components/Scroll/Scroll';

import { AuthContextProvider } from './context/AuthContext';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from './backend/firebase';
import NotFound from './pages/NotFound';

function App() {
  const [foundItems, setFoundItems] = useState([]);
  const [lostItems, setLostItems] = useState([]);

  const getFoundItems = async () => {
    const collectionRef = collection(db, 'foundItems');
    onSnapshot(collectionRef, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const foundItem = {
          id: docChange.doc.id,
          ...docChange.doc.data()
        };

        setFoundItems((prevFoundItems) => [...prevFoundItems, foundItem]);
      });
    });
  };

  const getLostItems = async () => {
    const collectionRef = collection(db, 'lostItems');
    onSnapshot(collectionRef, (snapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const lostItem = {
          id: docChange.doc.id,
          ...docChange.doc.data()
        };

        setLostItems((prevLostItems) => [...prevLostItems, lostItem]);
      });
    });
  };

  useEffect(
    () => {
      getFoundItems();
      getLostItems();
    },
    foundItems,
    lostItems
  );

  return (
    <div className="App overflow-hidden bg-white">
      <AuthContextProvider>
        <NavbarComponent />
        <div className="min-h-screen">
          <Scroll />
          <Routes>
            <Route path="/" element={<HomePage foundItems={foundItems} lostItems={lostItems} />} />
            <Route path="/items/:id" element={<SingleItemPage />} />
            <Route path="/lostItems" element={<LostItemsPage lostItems={lostItems} />} />
            <Route path="/foundItems" element={<FoundItemsPage foundItems={foundItems} />} />

            <Route
              path="/uploadItem"
              element={
                <ProtectedRoutes>
                  <UploadItemPage />
                </ProtectedRoutes>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoutes>
                  <ProfilePage />
                </ProtectedRoutes>
              }
            />
            <Route path="/signin" element={<AuthPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </AuthContextProvider>
    </div>
  );
}

export default App;
