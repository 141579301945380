import { useRef, useState } from 'react';
import { useLocation , useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { addDoc, collection } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../backend/firebase';
const UploadItemPage = () => {
  const { currentUser } = UserAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const itemNameRef = useRef();
  const itemDescRef = useRef();
  const PhoneRef = useRef();
  const [disabled , setDisabled] = useState(false);
  const [itemImage, setItemImage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [imageErrorMessage, setImageErrorMessage] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const [descErrorMessage, setDescErrorMessage] = useState('');
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const createItemPost = async () => {
    setDisabled(true);
    const destConnection = location.state.action === 'lost' ? 'lostDraft' : 'foundDraft';
    const post = {
      itemName: itemNameRef.current.value,
      itemDesc: itemDescRef.current.value,
      date: formatDate(new Date()),
      image: await handleBlogImageUpload(),
      authId: currentUser.uid,
      authName: currentUser.displayName,
      authImage: currentUser.photoURL,
      authRole: location.state.action === 'lost' ? 'خاوەن' : 'دۆزەرەوە',
      category: location.state.action,
      phone: PhoneRef.current.value,
    }
    try {
      await addDoc(collection(db, destConnection), {
        post
      });
      setSuccessMessage('پۆستەکەت پە سەرکەوتووی نێردرا');
      window.scrollTo(0, 0 , 'smooth');
      setTimeout(() => {
        navigate(destConnection === 'lostItems' ? '/lostItems' : '/foundItems');
      }, 5000);
  

    } catch (error) {
      window.scrollTo(0, 0 , 'smooth');
      setErrorMessage('پۆستەکەت نەنێردرا');
      setDisabled(false);
    }
  };

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/ ${month}/ ${year}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setNameErrorMessage('');
    setDescErrorMessage('');
    setSuccessMessage('');
    if (itemImage === '') {
      return setErrorMessage('تکایه وێنەیەک هەڵبژێره');
    }
    if (itemNameRef.current.value === '') {
      return setNameErrorMessage('تکایه ناوی شت بنووسه');
    }
    if (itemDescRef.current.value === '') {
      return setDescErrorMessage('تکایه وەسفی شت یان زانیاری زیاتر بنووسه');
    }
    
    if (PhoneRef.current.value === '') {
      return setPhoneErrorMessage('تکایه ژمارەی تەلەفۆنەکەت بنووسه');
    }
    await createItemPost();
  };

  const handleCancel = () => {
    setErrorMessage('');
    setSuccessMessage('');
    setItemImage('');
    setDescErrorMessage('');
    setNameErrorMessage('');
    itemNameRef.current.value = '';
    itemDescRef.current.value = '';
    PhoneRef.current.value = '';
    navigate('/');
  };
  const handleBlogImageUpload = async () => {
    const fileRef = ref(storage, `items/${currentUser.uid}/item-image/${itemImage.name}`);
    const uploadTask = await uploadBytes(fileRef, itemImage);
    const downloadURL = await getDownloadURL(uploadTask.ref);

    return downloadURL;
  };
  const handleItemImage = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const fileType = file.type.split('/')[0];
      if (fileType !== 'image') {
        return setImageErrorMessage('تکایه تەنها وێنە هەڵبژێره');
      }
      // if (file.size > 1024 * 1024) {
      //   return setErrorMessage('تکایه با قەبارەی وێنه له ١-مێگابایت کەمتر بێت');
      // }
    }

    return setItemImage(e.target.files[0]);
  };

  return (
    <form
      onSubmit={handleSubmit}
      
      className="font-raber39 p-10 flex justify-center items-center gap-10 flex-col"
    >
      {errorMessage && (
        <div
          className="p-4 mb-4 text-sm bg-red-50 text-red-700 rounded-lg text-center "
          
          role="alert"
        >
          <span className="font-medium">{errorMessage}</span>
        </div>
      )}
      {successMessage && (
        <div
          className="p-4 mb-4 text-sm text-green-700 rounded-lg bg-green-50 text-center "
          
          role="alert"
        >
          <span className="font-medium">{successMessage}</span>
        </div>
      )}

      <div className="image-div h-72 sm:h-64 md:h-96 w-full md:w-[750px] mx-auto">
        <img
          className=" h-72 sm:h-64 md:h-96 md:w-2/3  mx-auto rounded-md object-cover object-center"
          src={
            itemImage
              ? URL.createObjectURL(itemImage)
              : 'https://flowbite.com/docs/images/examples/image-1@2x.jpg'
          }
          alt="description"
        />
      </div>
      {imageErrorMessage && (
        <div
          className="p-4 mb-1 text-xs bg-red-50 text-red-700 rounded-lg text-center "
          
          role="alert"
        >
          <span className="font-medium">{imageErrorMessage}</span>
        </div>
      )}
      <div>
        <label
          className="block mb-2 text-sm font-medium text-stone-600 text-right"
          htmlFor="file_input"
        >
          وێنەی شت
        </label>
        <input
          onChange={handleItemImage}
          className="block w-full text-sm text-stone-500 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
          aria-describedby="file_input_help"
          id="file_input"
          type="file"
        />

        <div className=" text-xs text-stone-500 text-right mt-4 " id="blog-image">
          تکایه وێنەیەکی ڕوون و جوان هەڵبژێره
        </div>
      </div>
      {nameErrorMessage && (
        <div
          className="p-4 mb-1 text-xs bg-red-50 text-red-700 rounded-lg text-center"
          
          role="alert"
        >
          <span className="font-medium">{nameErrorMessage}</span>
        </div>
      )}
      <div className="">
        <span className="block mb-2 text-sm font-medium text-stone-600 text-right">ناوی شت</span>

        <input
          type="text"
          id="item-Name"
          ref={itemNameRef}
          placeholder="جزدانی پیاوان، پشیلەیەکی سوور، هتد"
          className=" w-72 md:w-96 rounded-lg bg-gray-50 border border-gray-300  text-stone-500 text-right focus:ring-blue-500 focus:border-blue-500  text-xs p-2.5  placeholder-stone-400 "
        />
        {descErrorMessage && (
          <div
            className="p-4 mt-5 text-xs bg-red-50 text-red-700 rounded-lg text-center "
            role="alert"
          >
            <span className="font-medium">{descErrorMessage}</span>
          </div>
        )}
      </div>
      <div className="w-full lg:w-1/2 mx-auto ">
        <span className="block mb-2 text-sm font-medium text-stone-600 text-right">
          وەسف و شوێنی دۆزینەوە
        </span>

        <textarea
          id="blog-body"
          rows="5"
          ref={itemDescRef}
          className="block   w-full bg-gray-50 rounded-lg border border-gray-300  text-stone-500 text-right focus:ring-blue-500 focus:border-blue-500  text-xs p-2.5  placeholder-stone-400"
          placeholder="جزدانێکی ڕەنگ قاوەی که تەسکەرەیەک و"
        />
      </div>
      <div className="">
        <span className="block mb-2 text-sm font-medium text-stone-600 text-right">ژمارە مۆبایل</span>

        <input
          type="tel" id="phone" placeholder="بۆ نمونە. 0770192900900" 
          ref={PhoneRef}
          className=" w-72 md:w-96 rounded-lg bg-gray-50 border border-gray-300  text-stone-500 text-right focus:ring-blue-500 focus:border-blue-500  text-xs p-2.5  placeholder-stone-400 "
        />
        {phoneErrorMessage && (
          <div
            className="p-4 mt-5 text-xs bg-red-50 text-red-700 rounded-lg text-center "
            role="alert"
          >
            <span className="font-medium">{phoneErrorMessage}</span>
          </div>
        )}
      </div>
      <div className="save-button flex flex-wrap flex-row-reverse gap-6 mt-10">
        <button
          type="submit"
          
          className={`btn ${disabled && 'btn-disabled' }  btn-primary sm:btn-lg md:btn-md rounded-full font-raber39 tracking-wide  bg-green-400 hover:bg-green-500 border-gray-500 hover:border-gray-500 shadow-sm`}
        >
          بڵاوی بکەرەوە
        </button>
        <button
          onClick={handleCancel}
          type="button"
          
          className={`btn  ${disabled && 'btn-disabled' }  text-stone-500 sm:btn-lg md:btn-md rounded-full font-raber39 tracking-wide hover:text-stone-300  bg-transparent  border-stone-500  shadow-sm`}
        >
          گەڕانەوە
        </button>
      </div>
    </form>
  );
};
export default UploadItemPage;
