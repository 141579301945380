import React from 'react';
import bike from '../../images/bike.png';
import wallet from '../../images/wallet.png';
import dog from '../../images/dog.png';
import laptop from '../../images/laptop.png';
import book from '../../images/book.png';
import bag from '../../images/bag.png';

const HeroSection = () => {
  return (
    <div className=" overflow-hidden relative h-[500px] sm:h-[600px] md:h-[700px] lg:h-[800px] xl:h-[900px] sm:max-w-6xl mx-auto flex justify-center items-center">
      <h1 className=" z-10 font-raber39 text-[2.70rem] sm:text-6xl md:text-7xl xl:text-8xl  text-stone-700 pb-20">
        دیارنەما
        <span> ؟</span>
      </h1>

      <div className="absolute top-0 md:top-8 hover:cursor-pointer transition-all group">
        <img
          src={bike}
          className=" relative z-3 w-48 sm:w-60 md:w-72 lg:w-80 xl:w-80  group-hover:scale-0 transition duration-300"
          alt='this is a bike'
        />
      </div>

      <div className="absolute top-24 right-4 sm:right-10 md:right-20  hover:cursor-pointer transition-all group ">
        <img
          src={wallet}
          className=" relative z-3 w-14 sm:w-20 md:w-24 lg:w-28 xl:w-32 2xl:w-36 rotate-12 group-hover:scale-0 transition duration-300 "
          alt='this is a wallet'
        />
      </div>

      <div className="absolute bottom-36 -right-14 md:right-3  hover:cursor-pointer transition-all group ">
        <img
          src={dog}
          className=" relative z-20 w-36 sm:w-48 md:w-56 lg:w-60 xl:w-64 2xl:w-72  group-hover:scale-0 transition duration-300"
          alt='this is a dog'
        />
      </div>

      <div className="absolute bottom-24 sm:bottom-28  ml-10 hover:cursor-pointer transition-all  group ">
        <img
          src={laptop}
          className=" relative z-20 w-32 sm:w-48 md:w-64 lg:w-68 xl:w-72  group-hover:scale-0 transition duration-300 "
          alt='this is a laptop'
        />
      </div>

      <div className="absolute top-28 left-4 sm:left-8 md:left-14  2xl:top-28 hover:cursor-pointer transition-all group ">
        <img
          src={book}
          className=" relative z-20 w-16 sm:w-24 md:w-28 lg:w-36 group-hover:scale-0 transition duration-300 "
          alt='this is a book'
        />
      </div>

      <div className="absolute bottom-40 left-6 sm:left-10 md:left-20  hover:cursor-pointer transition-all group ">
        <img
          src={bag}
          className=" relative z-20 w-24 sm:w-32 md:w-40 lg:w-44 xl:w-48 group-hover:scale-0 transition duration-300 "
          alt='this is a bag'
        />
      </div>
    </div>
  );
};

export default HeroSection;
