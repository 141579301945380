import React from 'react';
import NavItem from './NavItem';
import RegisterButton from '../Buttons/RegisterButton';
import { Link } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import getHighResolutionImage from '../../scripts/imageEnhancer';
import { useDisclosure } from '@mantine/hooks';
import { Drawer, Group } from '@mantine/core';
import Humberger from './Humberger';

const LINKS = [
  { title: 'سەرەکی', path: '/' },
  { title: 'ونبوو', path: '/lostItems' },
  { title: 'دۆزراوه', path: '/foundItems' }
];

function DropDown() {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const { currentUser, signOut } = UserAuth();
  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  return (
    <>
      <Drawer
        opened={opened}
        position="right"
        onClose={close}
        withCloseButton={false}
        overlayProps={{ opacity: 0.85 }}
        size="80%">
        <Drawer.Header>
          <Drawer.Title className=" w-full text-center"></Drawer.Title>
          <Drawer.CloseButton size={'md'} />
        </Drawer.Header>
        <Drawer.Body className="overflow-hidden">
          <ul className="flex flex-col justify-center items-center gap-10 text-xl text-center   bg-white hover:text-white">
            {currentUser && (
              <Link to={'/profile'} onClick={close}>
                <section className="flex justify-center items-center gap-2 ">
                  <figure className="w-16">
                    <img
                      className="mask mask-squircle"
                      src={getHighResolutionImage(currentUser.photoURL)}
                      alt=""
                    />
                  </figure>

                  <div className="flex flex-col items-start">
                    <p className=" text-base font-bold text-stone-600">{currentUser.displayName}</p>
                    <p className=" text-xs font-bold text-stone-500 truncate ">
                      {currentUser.email}
                    </p>
                  </div>
                </section>
                <hr className="h-px bg-gray-200 mt-8 border-0 w-screen"></hr>
              </Link>
            )}
            {LINKS.map((link, index) => (
              <NavItem key={index} href={link.path} toggleDropDown={close}>
                {link.title}
              </NavItem>
            ))}
            {!currentUser ? (
              <Link to={'/signin'} onClick={close}>
                <RegisterButton />
              </Link>
            ) : (
              <button
                onClick={handleSignOut}
                className="btn btn-primary sm:btn-lg md:btn-xl lg:w-[18rem] lg:h-[4rem] xl:w-[20rem] xl:h-[5rem] xl:text-xl rounded-full font-raber39 tracking-wide bg-stone-500 hover:bg-red-600 border-gray-500 hover:border-gray-500">
                چوونەدەرەوە
              </button>
            )}
          </ul>
        </Drawer.Body>
        {/* Drawer content */}
      </Drawer>
      <Group position="center">
        <Humberger openDrawer={open} />
      </Group>
    </>
  );
}
export default DropDown;

// const DropDown = ({ toggleDropDown }) => {
//   const { currentUser, signOut } = UserAuth();
//   const navigate = useNavigate();
//   const handleSignOut = async () => {
//     await signOut();
//     navigate('/');
//   };
//   return (
//     <ul className=" h-screen z-50 w-full shadow-md absolute hero-overlay transition flex flex-col py-10   gap-10 text-xl text-center items-center justify-start bg-white hover:text-white md:hidden">
//       {currentUser && (
//         <Link to={'/profile'} onClick={toggleDropDown}>
//           <section className="flex flex-col justify-center items-center">
//             <figure className="w-20">
//               <img
//                 className="mask mask-squircle"
//                 src={getHighResolutionImage(currentUser.photoURL)}
//                 alt=""
//               />
//             </figure>
//             <div className="px-4 py-3 text-sm font-bold text-stone-500 ">
//               <div className=" truncate">{currentUser.email}</div>
//               <hr class="h-px bg-stone-200 mt-8 border-0 w-screen"></hr>
//             </div>
//           </section>
//         </Link>
//       )}

//       {LINKS.map((link, index) => (
//         <NavItem key={index} href={link.path} toggleDropDown={toggleDropDown}>
//           {link.title}
//         </NavItem>
//       ))}
//       {!currentUser ? (
//         <Link to={'/signin'} onClick={toggleDropDown}>
//           <RegisterButton />
//         </Link>
//       ) : (
//         <button
//           onClick={handleSignOut}
//           className="btn btn-primary sm:btn-lg md:btn-xl lg:w-[18rem] lg:h-[4rem] xl:w-[20rem] xl:h-[5rem] xl:text-xl rounded-full font-raber39 tracking-wide bg-stone-500 hover:bg-red-600 border-gray-500 hover:border-gray-500">
//           چوونەدەرەوە
//         </button>
//       )}
//     </ul>
//   );
// };
