import React from 'react';
import { whatsapp } from '../images/svg/svg';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const SingleItemPage = () => {
  const { itemId } = useParams();

  const location = useLocation();

  useEffect(() => {}, [itemId]);
  return (
    <div className="font-raber39 flex flex-col justify-end gap-5 mt-5 lg:mt-9 px-5 sm:px-10 md:px-16 md:max-w-[48rem] md:mx-auto pt-10 ">
      <div className="flex flex-row-reverse justify-between  items-center w-full ">
        <div className="flex flex-row-reverse gap-2 ">
          <img
            className="w-10 h-10 mask mask-squircle object-cover object-center"
            src={location.state.itemData.authImage}
            alt=""
          />

          <div className="font-medium text-sm flex flex-col justify-center items-end ">
            <div className="text-stone-800">{location.state.itemData.authName}</div>
            <div className="text-xs text-stone-600 ">{location.state.itemData.authRole}</div>
          </div>
        </div>
        <div className="font-raber39 px-3 py-2 text-sm text-stone-600  rounded border border-stone-500">
          {location.state.itemData.date}
        </div>
      </div>

      <img
        className="w-full sm:w-[30rem] mx-auto h-96 sm:h-[28rem]  mt-3 object-cover object-center rounded border border-stone-500"
        src={location.state.itemData.image}
        alt=""
      />

      <div className="flex flex-row-reverse justify-between gap-5 items-center mr-5">
        <div className=" flex-1 text-sm md:text-base text-stone-800 text-end  ">
          {location.state.itemData.itemName}
        </div>
        {/* <div className=" font-raber39 flex flex-initial h-10 flex-row-reverse items-center gap-1  text-[0.52rem] bg-orange-600/80 border border-stone-600  px-3 rounded-full  text-white">
          {clock}
          <span className="font-sans text-xs font-bold">٤٥</span>خولەک
        </div> */}
      </div>

      <div className="min-h-16 mt-1 bg-stone-50 rounded text-xs px-4 py-4 text-stone-600 text-end">
        {location.state.itemData.itemDesc}
      </div>

      <a
        href="https://wa.me/+96400"
        type="button"
        target="_BLANK"
        rel="noopener noreferrer"
        className="text-white mt-2  justify-center bg-green-600 hover:bg-green-600/90 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5  inline-flex items-center  mr-2 mb-2"
      >
        {location.state.itemData.category === 'found'
          ? 'تکایه ئاگادارم بکه'
          : 'تکایه پەیوەندیم پێوه بکه'}
        {whatsapp}
      </a>
      { location.state.itemData.phone && <a
        href={`tel://${location.state.itemData.phone}`}
        type="button"
        target="_BLANK"
        rel="noopener noreferrer"
        className="text-green-600 border border-green-600 sm:hidden justify-center  focus:ring-4   font-medium rounded-lg text-md px-5 py-2.5  inline-flex items-center mr-2"
      >
        
         
            {location.state.itemData.phone}
        
      </a>}
    </div>
  );
};

export default SingleItemPage;
