import React from 'react';
import LostButton from '../Buttons/LostButton';
import FoundButton from '../Buttons/FoundButton';
import { Link } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';

const UploadSection = () => {
  const { currentUser } = UserAuth();

  return (
    <div className="flex justify-center gap-3 sm:gap-8 md:gap-12 lg:gap-16">
      <Link to={currentUser ? '/uploadItem' : '/signin'} state={{ action: 'lost' }}>
        <LostButton />
      </Link>
      <Link to={currentUser ? '/uploadItem' : '/signin'} state={{ action: 'found' }}>
        <FoundButton />
      </Link>
    </div>
  );
};

export default UploadSection;
