import React, { useEffect } from 'react';
import Card from '../Card/Card';
import useEmblaCarousel from 'embla-carousel-react';
import { Link } from 'react-router-dom';
import Empty from '../Empty/Empty';


const Carousel = ({ items , category }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    speed: 10,
    dragFree: true,
    align: 'start'
  });

  useEffect(() => {
    emblaApi && emblaApi.on('select', () => {});
  }, []);
  
  if (items.length === 0) return (
    
   <Empty category={category}/>

  );
  return (
    <div className="embla " ref={emblaRef}>
      <div className="embla__container flex gap-8 md:gap-10 lg:gap-12 w-full h-full py-2  ">
        {items.map((item) => (
           <Link key={item.id} state={{ itemData: item }} to={`/items/${item.id}`}>
          <div
            key={item.id}
            className="flex-initial relative flex-shrink-0 w-60 sm:w-56 md:w-64 lg:w-72 xl:w-80 2xl:w-96 p-1  "
          >
            <Card
              key={item.id}
              itemImage={item.image}
              itemName={item.itemName}
              date={item.date}
              authName={item.authName}
              authImage={item.authImage}
              authRole={item.authRole}
            />
            </div>
            </Link>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
