import React from 'react';
import background from '../images/background.jpg'
import LottieAnimation from '../components/Lottie/CoffieAnimation'
import animationData from '../images/lottie/coffee-loader-food-beverage.json';
import { UserAuth } from '../context/AuthContext';
import getHighResolutionImage from '../scripts/imageEnhancer';

const ProfilePage = () => {

  const { currentUser } = UserAuth();

  return (
    <div className="font-raber39 flex flex-col justify-end gap-5  sm:px-10 md:px-16 w-full md:mx-auto pt-10 ">
      <div className="w-full flex justify-center items-end border h-60mm md:h-72 lg:h-96 relative">
      <img
          className='w-full h-full object-cover object-center'
          src={background}
          alt="background"
        />
        <div className='absolute -bottom-14 bg-gray-50 p-0.5 mask mask-squircle'>
        <img
          className=" mx-auto w-28 sm:w-32 md:w-36 lg:w-40 xl:w-44 mask mask-squircle object-cover object-center"
          src={getHighResolutionImage(currentUser.photoURL)}
          alt=""
        />
        </div>
      </div>
      <div className="mx-auto flex flex-col items-center justify-center gap-1 mt-14 ">
        <h1 className=" text-lg lg:text-xl xl:text-2xl text-stone-800">{currentUser.displayName}</h1>
        <h2 className=" text-xs lg:text-sm xl:text-base text-stone-500">{currentUser.email} </h2>
      </div>
      <main className='flex flex-col justify-start items-end px-5 mt-2'>
        <h1 className='bg-gray-100 text-stone-500 p-2 rounded'>چالاکیەکان</h1>
        <div className=' flex flex-col justify-center items-center w-1/2 md:w-1/3 self-center'>
          <LottieAnimation animationData={animationData} />
          <h2 className='text-stone-500 text-xs sm:text-sm md:text-base lg:text-lg'>هیچ چالاکیەکت نیە لە ئێستایا</h2>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
