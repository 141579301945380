import React from 'react';
import { Link } from 'react-router-dom';

const CarouselLayout = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-5 md:gap-6 lg:gap-8 xl:gap-9 2xl:gap-10  my-10 py-3 sm:my-12 lg:my-16 xl:my-20 2xl:my-24 ">
      <div className="flex justify-between items-center mx-5 md:mx-10 lg:mx-12 font-raber39 text-stone-600">
        <Link to={title === 'شته وونبووەکان' ? '/lostItems' : '/foundItems'}>
          <div className="text-xs  md:text-sm xl:text-base   px-3 md:px-5 md:py-2 py-1 rounded-full text-orange-400 hover:text-orange-500   transition hover:cursor-pointer">
            هەمووی ببینە
          </div>
        </Link>
        <div className="text-sm font-bold md:text-base xl:text-lg  ">{title}</div>
      </div>

      {children}
    </div>
  );
};

export default CarouselLayout;
