import React from 'react';
import NavItem from './NavItem';
import { Link } from 'react-router-dom';
import RegisterButton from '../Buttons/RegisterButton';
import { UserAuth } from '../../context/AuthContext';
import UserDropDown from '../UserDropDown/UserDropDown';
import DropDown from './DropDown';

const LINKS = [
  { title: 'سەرەکی', path: '/' },
  { title: 'ونبوو', path: '/lostItems' },
  { title: 'دۆزراوه', path: '/foundItems' }
  // { title: 'دەرباره', path: '/about' }
];

const NavbarComponent = ({ toggleDropDown }) => {
  const { currentUser } = UserAuth();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light flex justify-between items-center px-10 py-5 lg:max-w-[85rem] mx-auto md:border-b md:border-b-gray-200">
      <Link to="/" className="font-bold font-raber39 text-lg text-stone-600">
        <div className="relative">
          <span className=" z-10 absolute left-0 blur-md w-8 h-8 bg-amber-500 rounded-full"></span>
          <p className="z-20 absolute"> دیارنەما</p>
        </div>
      </Link>

      <div className="md:hidden" onClick={toggleDropDown}>
        <DropDown />
      </div>

      <div className=" hidden md:block">
        <div className="flex items-center sm:gap-8 md:gap-10 lg:gap-24 2xl:gap-32">
          <ul className="flex flex-row-reverse justify-around gap-5 md:gap-8 lg:gap-12 font-medium text-gray-500">
            {LINKS.map((link, index) => (
              <NavItem key={index} href={link.path}>
                {link.title}
              </NavItem>
            ))}
          </ul>
          {currentUser ? (
            <UserDropDown />
          ) : (
            <Link to={'/signin'}>
              <RegisterButton />
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
