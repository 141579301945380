import React from 'react';
import PlaceCard from './PlaceCard';
import taxi from '../../images/places/taxi.png';
import airplane from '../../images/places/airplane.png';
import tea from '../../images/places/tea.png';

const places = [
  {
    id: 1,
    name: 'ناو تەکسی؟',
    image: taxi,
    color: 'from-[#FF0844] to-[#FFB199]'
  },
  {
    id: 2,
    name: 'فڕۆکەخانە؟',
    image: airplane,
    color: 'from-[#00c6FB] to-[#005BEA]'
  },

  {
    id: 3,
    name: 'کافێ؟',
    image: tea,
    color: 'from-[#F6D365] to-[#FDA085]'
  }
];

const PlacesSection = () => {
  return (
    <div className="mx-2 sm:mx-4 md:mx-10 my-20 py-10 sm:py-20 lg:py-24 px-4 rounded-2xl flex flex-col justify-center gap-10 sm:gap-14 md:gap-20 lg:gap-28 bg-zinc-50-50">
      <div className="font-raber39 text-stone-700 text-center text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl ">
        لە کوێ؟
      </div>
      <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 md:gap-20 lg:gap-32 xl:gap-48 justify-center items-center ">
        {places.map((place) => (
          <div
            key={place.id}
            className="flex flex-col  gap-2 md:gap-3  lg:gap-5 xl:gap-6  justify-center items-center"
          >
            <PlaceCard key={place.id} name={place.namme} image={place.image} color={place.color} />
            <div className="text-stone-500 font-raber39 text-xl md:text-2xl lg:text-3xl xl:text-4xl opacity-90 ">
              {place.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlacesSection;
