import React from 'react';

const FoundButton = () => {
  return (
    <button
      type="button"
      className="btn btn-primary border-none sm:btn-lg md:btn-xl lg:w-[18rem] lg:h-[4rem] xl:w-[20rem] xl:h-[5rem] xl:text-xl rounded-full font-raber39 tracking-wide bg-green-400 hover:bg-green-500 "
    >
      شتێکت دۆزیوەتەوە؟
    </button>
  );
};

export default FoundButton;
