import React from 'react'
import nothing from '../../images/nothing.png';

const Empty = ({category}) => {
  return (
    <div className="relative flex flex-col justify-center items-center">
    <img src={nothing} alt="nothing" className="max-w-xs mx-auto xl:max-w-sm" />
    <h1 className=" absolute text-center text-base md:text-lg lg:text-3xl font-raber39 font-bold text-gray-500 mt-4 bg-gray-100/90 rounded p-4"> هیچ شتێک { category === 'lostItems' ? 'ون نەبوە' : 'نەدۆزراوەتەوە'}</h1>
   </div>
  )
}

export default Empty