import React, { useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import getHighResolutionImage from '../../scripts/imageEnhancer';

const UserDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, signOut } = UserAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    navigate('/');
  };

  return (
    <div className="font-raber39 ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        id="dropdownAvatarNameButton"
        data-dropdown-toggle="dropdownAvatarName"
        className="flex  items-center text-sm font-medium text-stone-800 transition rounded-full hover:text-amber-500 md:mr-0 "
        type="button"
      >
        <span className="sr-only">Open user menu</span>

        <svg
          className="w-4 h-4 mx-1.5"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
        {currentUser.displayName}
        <img className="w-8 h-8 ml-2 mask mask-squircle" src={getHighResolutionImage(currentUser.photoURL)} alt="" />
      </button>

      <div
        id="dropdownAvatarName"
        className={`z-10 ${
          isOpen ? 'inline-block' : 'hidden'
        } absolute right-2 2xl:right-24 text-right bg-white divide-y divide-stone-100 rounded-lg shadow w-44 `}
      >
        <div className="px-4 py-3 text-sm text-stone-500 ">
          <div className=" truncate">{currentUser.email}</div>
        </div>
        <ul
          className="py-2 text-sm text-stone-700 dark:text-gray-200"
          aria-labelledby="dropdownInformdropdownAvatarNameButtonationButton"
        >
          <li>
          
             <Link to="/profile" className="block px-4 py-2 hover:bg-stone-100 hover:cursor-pointer ">
                هەژمار
              </Link>
            
          </li>
          
        </ul>
        <div className="py-2">
          <button
            onClick={handleSignOut}
            className="block px-4 py-2 text-sm w-full text-stone-700 hover:bg-red-200 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
          >
            چوونەدەرەوە
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserDropDown;
