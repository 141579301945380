import React from 'react';

const LostButton = () => {
  return (
    <button
      type="button"
      className="btn btn-primary border-none sm:btn-lg md:btn-xl lg:w-[18rem] lg:h-[4rem] xl:w-[20rem] xl:h-[5rem] xl:text-xl rounded-full font-raber39 tracking-wide bg-red-400 hover:bg-red-500  "
    >
      شتێکت ون کردوه؟
    </button>
  );
};

export default LostButton;
