import React from 'react';
import { Link } from 'react-router-dom';
import { instagramLogo } from '../../images/svg/svg';

const Footer = () => {
  return (
    <footer className="font-raber39  footer items-center py-10 px-5 mt-10 bg-stone-50 text-stone-500 border-t">
      <div className="items-center flex justify-between w-full ">
        <Link
          to="/"
          className="font-bold font-raber39 text-lg  hover:text-orange-500 hover:cursor-pointer transition-all"
        >
          دیارنەما
        </Link>

        <div className="flex flex-col justify-center items-center content-center  text-[10px] sm:text-xs md:text-sm mr-5 gap-1">
          <p>Copyright © 2023</p>
          <p>هەموو مافێکی پارێزراوه بۆ <a className="hover:bg-stone-600 transition bg-stone-500 px-2 py-0.5 text-white rounded" target={'_blank'} href="http://www.instagram.com/bnchina/" rel="noreferrer">بنچینه</a></p>
        </div>
        <a className='hover:cursor-pointer hover:text-orange-500 transition' target={'_blank'} href="https://www.instagram.com/dyarnama.app" rel="noreferrer">
          
        {instagramLogo}
        
        </a>
      </div>
    </footer>
  );
};

export default Footer;
