import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import UploadSection from '../components/UploadSection/UploadSection';
import PlacesSection from '../components/PlacesSection/PlacesSection';
import CarouselLayout from '../components/CarouselSection/CarouselLayout';
import Carousel from '../components/CarouselSection/Carousel';

const HomePage = ({ foundItems, lostItems }) => {
  return (
    <div className="z-0">
      <HeroSection />
      <UploadSection />
      <PlacesSection />
      <CarouselLayout title="شته وونبووەکان">
        <Carousel items={lostItems} category="lostItems" />
      </CarouselLayout>
      <CarouselLayout title="شته دۆزراوەکان">
        <Carousel items={foundItems} category="foundItems" />
      </CarouselLayout>
    </div>
  );
};

export default HomePage;
